
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "incline-licensed-distributor-associate",
  props: ["accountid"],
  components: {},
  setup(props) {
    const store = useStore();

    const accountInfo = computed(() => {
      return store.getters.getLicensedDistAssociateAccountInfo(props.accountid);
    });

    const licensedDistAssociateInfo = computed(() => {
      return store.getters.getLicensedDistributorAssociateInfo(
        String(props.accountid)
      );
    });

    const { d } = useI18n();

    const userRole = computed(() => store.getters.currentUserRole);

    return {
      accountInfo,
      licensedDistAssociateInfo,
      userRole,
      d,
    };
  },
});
